<script>
  import { shortDate } from '../utils.js'
  import DownloadLinkButton from './DownloadLinkButton.svelte'
  import { getContext } from 'svelte'
  import { USERPUBSCHECKLIST } from './tabs/UserPubsTab.svelte'
  export let item
  //  export let category

  const checkList = getContext(USERPUBSCHECKLIST)
  $: checked = $checkList.contains(item)

  const byBatch = getContext('byBatch')

  const toggleCheck = () => {
    if (!checked){
      checkList.add(item)
    } else {
      checkList.remove(item)
    }
  }
</script>

<div class="table_row" class:recent={!$byBatch && item.recent} class:active={true} >
  <span class="table_item u-flex-grow6">
    {#if item.sublabel}
      <span class="sublabel">{item.sublabel||''}</span>
      <br/>&#x21B3;
    {/if}
    <span class="pub_name">{item.name}</span>
  </span>
  <span class="table_item update_date u-flex-grow1">
    {shortDate(item.last_local_update_date)}</span>
 <span class="table_item u-flex-grow1" >
   <DownloadLinkButton
          mode='alacarte'
          resourceType={'pub'}
          ids={`${item.pub_class}|${item.id}`}
          buttonText={""}
   />
   {#if $byBatch}
     <div class="checkbox_button">
       <input type="checkbox" on:change={toggleCheck} bind:checked={checked} />
     </div>
   {/if}
 </span>
</div>
