<script context="module">
  export const USERPUBSCHECKLIST = {}
</script>

<script>
  import API from '../../api.js'
  import { vessel } from '../../stores.js'
  import { longDateTime,
    shortDate,
    replaceDateStrings,
    pluralize } from '../../utils.js'
  import DatePicker from 'svelte-calendar/src/Components/Datepicker.svelte'
  import DownloadLinkButton from '../DownloadLinkButton.svelte'
  import UserPubRow from '../UserPubRow.svelte'
  import createCheckList from '../../createCheckList.js'
  import { onMount, setContext } from 'svelte'
  import { fly } from 'svelte/transition'
  import { writable } from 'svelte/store'
  import VirtualList from '../../vendor/VirtualList.svelte'

  const checkList = createCheckList()
  setContext(USERPUBSCHECKLIST, checkList)

  $: numCheckedFiles = ($checkList && $checkList.list && $checkList.list.length) || 0

  const byBatch = writable(false)
  setContext('byBatch', byBatch)

  let categories = []

  let since

  let lastDownloadDate = $vessel.last_pub_download_date
  $: lastDownloadDate = $vessel.last_pub_download_date
  $: $vessel, since = null

  $: downloadSince = since || lastDownloadDate

  $: downloadSince, $vessel, getNumNew(downloadSince)

  const earliestDate = new Date()
  earliestDate.setMonth(earliestDate.getMonth() - 12);

  const pubCats = [
    {name: 'USCG Light Lists', className: 'uscg_lol'},
    {name: 'NGA Lists of Lists', className: 'nga_lol' },
    {name: 'USCG Notices to Mariners', className: 'uscg_ntm' },
    {name: 'NGA Notices to Mariners', className: 'nga_ntm' },
    {name: 'CHS Notices to Mariners', className: 'ccg_ntm'},
    {name: 'NOAA Piloting Guides', className: 'noaa_coast_pilot'}
  ]

  let pubs = []

  $: pubs = pubs.map( (pub) => {
         pub.recent = downloadSince < pub.last_local_update_date
         return pub
     })

  const successfulDownload = () => {
    const rightNow = new Date()
    vessel.update( (vessel)=>({...vessel,
      last_pub_download_date: rightNow,
      num_new_pubs: 0
    }))
    // console.log("vessel.last_pub_download_date = " + $vessel.last_pub_download_date)
  }

  const setSince =(e)=>{
    // console.log('since changed' + e)
    since = e.detail.date
  }

  const showCat = (category) => {
     // console.log("category.showing = " + category.showing)

     if (category.showing){
       categories.forEach( (cat) => cat.showing = false )
       pubs = []
       return false
     }

     category.loading = true
     let catId = category.className

     if (category.className === 'other_pub'){
       catId += ',' + category.id
     }

     API.get(`/api/pubs/${catId}`)
      .then( json => pubs = replaceDateStrings(json,["last_local_update"]).map(p=>({...p, catId: catId})))

     categories.forEach( (cat) => cat.showing = false )
     category.loading = false
     category.showing = true
   }

   onMount( () => {
     API.get('api/other_pub_categories').then((json)=>{
        categories =
         [...pubCats.map(p=>Object.assign(p, {selected: 0, numNew: 0})),
         ...(json.map(
               (c)=> Object.assign(c,{className: `other_pub,${c.id}`, selected: 0, numNew: 0})
            )) ]
        console.log("categories: " + JSON.stringify(categories))

        categories.forEach((cat)=> {
           cat.showing = false
           cat.loading = false
           cat.loaded = false
        })
     })
   })

  const numNew = writable({total: $vessel.num_new_pubs})
  setContext('numNew', numNew)

  const getNumNew = (newSinceDate) => {
    API.get(`api/pubs/new_since/?since=${encodeURIComponent(newSinceDate)}`)
      .then( (json) => {
        json.other_pub_categories.map((h)=>{
          json[h["name"]] = h["count"]
        })
        numNew.set(json)
      })
  }

  $: numNewString = `${$numNew["total"]||'No'} ${pluralize($numNew["total"],'pub')}`

</script>

<div class="download_options">

    <div class="download_type">
      {#if $byBatch}
        <a href="#" on:click|preventDefault={()=>{byBatch.set(false); return false;}}
        >
          Download Latest
        </a>
        |
        <span>
          Choose Files
        </span>
      {:else}
        <span >
          Download Latest
        </span>
        |
        <a href="#" on:click|preventDefault={()=>{byBatch.set(true); return false;}}>
          Choose Files
        </a>
      {/if}
    </div>

      {#if $byBatch}
        <div class="download_info"
          in:fly={{ x: 100, duration: 400, delay: 400 }}
          out:fly={{ x: 100, duration: 400 }}>
          <div class="flex-column">
            <div>
              <div>{numCheckedFiles} {pluralize(numCheckedFiles, 'file')}</div>
              <div>size: {parseInt($checkList.totalSize / 1024**2)}M</div>
            </div>
            {#if numCheckedFiles}
              <div class="change_buttons">
                <button on:click={()=>checkList.clear()}>clear selections</button>
              </div>
            {/if}
         </div>
       </div>
      {:else}
        <div class="download_info"
          in:fly={{ x: -100, duration: 400, delay: 400 }}
          out:fly={{ x: -100, duration: 400 }}>
        {numNewString}
        updated since
          <span class="download_date">
          {longDateTime(downloadSince)}
          </span>

        <div class="change_buttons">
            <div>
              <DatePicker
                end={new Date()}
                start={earliestDate}
                on:dateSelected={ e => since = e.detail.date }>
                <button class="custom-button">change date</button>
              </DatePicker>
            </div>

            {#if downloadSince != lastDownloadDate}
              <div>
                <button on:click={ () => since = lastDownloadDate }>
                  reset to my last download date
                </button>
              </div>
            {/if}
        </div>
        </div>
      {/if}

    <div class="download_buttons">
      {#if !$byBatch}
          <DownloadLinkButton
             resourceType={'pub'}
             since={downloadSince}
             on:success={successfulDownload}
             buttonClass={"large_download"}
             disabled={$numNew.total < 1}
           >
           <div slot="buttonText">
             <i class="fas fa-download" />
             Download
           <div>
         </DownloadLinkButton>
      {:else}
         <DownloadLinkButton
              resourceType='pub'
              mode='alacarte'
              ids={$checkList.idString()}
              buttonClass={"large_download"}
              disabled={numCheckedFiles == 0}
            >
              <div slot="buttonText">
                <i class="fas fa-download" />
                Download
              </div>
            </DownloadLinkButton>
      {/if}
    </div>

</div>

<div class="user_pubs_list">
  {#each categories as category (category)}

    <h3>
      <button on:click={()=>showCat(category)}>
        {#if category.showing}-{:else}+{/if}
      </button>
      <span class="num_pubs" >
        {category.name}
        <span class="category_count"
        class:any={ (!$byBatch && ($numNew[category.name]||$numNew[category.className])) ||
                    ($byBatch && $checkList.selected(category)) }  >
      {#if !$byBatch}
        {$numNew[category.name] || $numNew[category.className] || 0} new
      {:else}
        {$checkList.selected(category)} selected
      {/if}
      </span>

    </h3>
      {#if category.showing}
        <div class="vl-container"
          style="min-height: 4rem; height: {(pubs.length * 2) + 8}rem; max-height: 20rem;" >
          {#if !$byBatch && ($numNew[category.name] || $numNew[category.className])}
            <div class="notes">
              green rows are updated since your last download
            </div>
          {/if}
          <VirtualList items={pubs} let:item >
            <UserPubRow {item} {category} />
         </VirtualList>
       </div>
      {/if}
  {/each}


</div>

 <style>
 </style>
