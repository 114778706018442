<script>

		const regExpEscape = (s) => {
			return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
		}

			export let name= '';
			export let value= '';
			export let placeholder = '';
			export let required= false;
			export let disabled= false;
      export let objectId = '';

			// autocomplete props
			export let items= [];
			export let isOpen= false;
			export let results= [];
			export let search= '';
			export let isLoading= false;
			export let arrowCounter= 0;
      export let isAsync= false;
      export let useObjectId= false;
      export let searchFunc = () =>{}
      export let valueChanged = () => {}
      export let fromStart= true; // Default type ahead


      let className= '';
      let minChar= 2;
      let maxItems= 10;
			let list;
			let input;

			async function onChange (event) {

        console.log("onChange")
        console.log("isAsync = " + isAsync)


				// Is the data given by an outside ajax request?
				if (isAsync) {
					isLoading = true;
          searchFunc(search).then( (data) => {
            items = data
					  isLoading = false;
            filterResults();
					  isOpen = true;
          });
				} else if (search.length >= Number(minChar)) {
					filterResults()
					isOpen = true;
				}
			}

			function filterResults () {
        const upperSearch = search.toUpperCase()
        const filteredItems = items.filter(item => {
					if (typeof item !== 'string') {
						item = item.key || '' // Silent fail
					}

					return fromStart ? item.toUpperCase().startsWith(upperSearch)
													 : item.toUpperCase().includes(upperSearch)
				})

        results = filteredItems.map(item => {
          // key is the full label
          // value is the search string
          // id is the objectId

					const text = typeof item !== 'string' ? item.key : item

          const obj = {
						key: text,
						value: item.value || item,
						label: search.trim() === '' ?
                      text :
                      text.replace(RegExp(regExpEscape(search.trim()), 'i'), "<span>$&</span>")
					}
          if (useObjectId){
            obj.id = item.id
          }
          return obj
 				});

				const height = results.length > maxItems ? maxItems : results.length
				list.style.height = `${height * 2.25}rem`
			}

    function onKeyDown (event) {
      if (event.keyCode === 40 && arrowCounter < results.length) {
        // ArrowDown
        arrowCounter =  arrowCounter + 1
      } else if (event.keyCode === 38 && arrowCounter > 0) {
        // ArrowUp
        arrowCounter =  arrowCounter - 1;
      } else if (event.keyCode === 13) {
        // Enter
        event.preventDefault()
        if (arrowCounter === -1) {
          arrowCounter = 0 // Default select first item of list
        }
        close(arrowCounter)
      } else if (event.keyCode === 27) {
        // Escape
        event.preventDefault()
        close()
      }
    }

    function close (index = -1) {
      isOpen = false;
			arrowCounter = -1;
     	input.blur();
      if (index > -1) {
      	value = results[index].value;
        search = value
        objectId = results[index].id;
        valueChanged({value: value, id: objectId})
      } else if (!value) {
        search = ''
      }
    }


  function onupdate ({ changed, current }) {
    if (isAsync && changed.items && current.items.length) {
       items = current.items;
       isLoading = false;
       isOpen = true;
       filterResults();
    }
	}
</script>


<svelte:window on:click="{()=>close()}" />
<div on:click="{(event)=>event.stopPropagation()}" class="autocomplete">
  <input
    type="text"
    class="{className}"
    {name}
    {placeholder}
    {required}
    {disabled}
    autocomplete="off"
    bind:value="{search}"
    on:input="{(event)=>onChange(event)}"
    on:focus
    on:blur
    on:keydown="{(event)=>onKeyDown(event)}"
    bind:this={input}
  >
  <ul class="autocomplete-results{!isOpen ? ' hide-results' : ''}" bind:this={list}>
		{#each results as result, i}
				<li on:click="{()=>close(i)}" class="autocomplete-result{ i === arrowCounter ? ' is-active' : '' }">
				{@html result.label}
				</li>
		{/each}
  </ul>
{#if isLoading}
  <slot>
    <p class="fallback">Loading data...</p>
  </slot>
{/if}
</div>
