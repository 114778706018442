<script>
   import { onMount } from 'svelte'
   import API from '../api.js'
   import {currentWeek} from '../stores.js'
   let week

   onMount(
     () => {
       console.log("masthead mounting")
       console.log("currentWeek: " + JSON.stringify($currentWeek))
       API.get('/current_week').then( json => {
         currentWeek.set(json)
         console.log("after mount: currentWeek = " + JSON.stringify($currentWeek))
       }
       )
       console.log("masthead mounted")
     }
   )
</script>

<div class="header">
  <div class="header_text">
    <div class="brand">
      Ocean Charting Systems Portal
    </div>
    <div class="current_week">
      {#if $currentWeek }
         <span>
           week: {$currentWeek.year}-{$currentWeek.week},
           {$currentWeek.complete ? 'complete' : 'partially complete'}
         </span>
      {/if}
    </div>
  </div>
</div>



<style>
  .current_week > span {
     background-color: #FFFFFF;
  }
</style>
