<script>
  import { shortDate } from '../utils.js'
  import DownloadLinkButton from './DownloadLinkButton.svelte'
  import { getContext } from 'svelte'
  export let item

  const byBatch = getContext('byBatch')
  const checkList = getContext('checkList')
  $:  checked = $checkList.contains(item)

  const toggleCheck = () => {
    if (!checked){
      checkList.add(item)
    } else {
      checkList.remove(item)
    }
  }
</script>

<div class="table_row"
     class:recent={!$byBatch && item.recent}
     class:active={true} >
  <span class="table_item update_date u-flex-grow1 date_span">
    {shortDate(item.last_local_update_date)}</span>
  <span class="table_item u-flex-grow6 pub_name">
    {item.name}: {item.chart.label}
   </span>
   <span class="table_item u-flex-grow1" >
   <DownloadLinkButton
          mode='alacarte'
          resourceType={'rnc'}
          ids={`${item.id}`}
          buttonText={""}
   />
   {#if $byBatch}
   <div class="checkbox_button">
     <input type="checkbox"
       on:change={toggleCheck}
       bind:checked={checked} />
   </div>
   {/if}
 </span>
</div>
