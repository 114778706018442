<script>
  import { onMount, setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import { getEncsJSON } from '../../api.js'
  import UserEncRow from '../UserEncRow.svelte'
  import VirtualList from '../../vendor/VirtualList.svelte'
  import DownloadButtonRow from '../DownloadButtonRow.svelte'
  import createCheckList from '../../createCheckList.js'

  const checkList = createCheckList()
  setContext('checkList', checkList)

  const byBatch = writable(false)
  setContext('byBatch', byBatch)

  const numNew = writable(0)
  setContext('numNew', numNew)

  const items = writable([])
  setContext('items', items)

  const loadENCs = async () => {
    //console.log("loading ENCs")
    items.set(await getEncsJSON())
  }

  onMount(
    loadENCs
  )

</script>

<DownloadButtonRow
  resourceType='enc'
/>

  <div class="downloads_list">
    {#if $items.length}
      {#if !$byBatch && $numNew}
        <div class="notes">
          green rows are updated since date specified
        </div>
      {/if}
      <div class="vl-container">
        <VirtualList items={$items} let:item >
          <UserEncRow {item} />
        </VirtualList>
      </div>
    {/if}
  </div>
