<script>
  import {account, vessel} from '../stores.js'
  import API from "../api.js"
  import { updateSession } from '../session.js'

  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  const setVessel = (ves) => {

    if ($account.vessels.indexOf(ves)>-1) {
       API.get('/api/my_account', {vessel_id: ves.id})
         .then(json => updateSession(json))
    }

    dispatch('vesselSet', ves)
  }
</script>

<span class="ulbase">
<ul>
 {#each $account.vessels as ves}
  <li>
      <button class:current={ves.id == $vessel.id} on:click={()=>setVessel(ves)} >
        {ves.name}
      </button>
  </li>
 {/each}
</ul>
</span>

 <style>
  ul {
    list-style: none;
    position: absolute;
    z-index: 10;
    top: -2rem;
    left: -10rem;
    width: 10rem;
    background-color: white;
    border: 1px solid #CCC;
    padding: .25rem;
  }

  button {
    width: calc(100% - .5rem);
  }

  li {
    padding: .25rem .25rem;
  }

  button.current {
    background-color: lightgreen;
  }
  span.ulbase {
    position: relative;
  }
 </style>
