import {dequal as isEqual} from 'dequal/lite';
import { forEach } from "property-expr";

function subscribeOnce(observable) {
  return new Promise(resolve => {
    observable.subscribe(resolve)(); // immediately invoke to unsubscribe
  });
}


function update(obj, path, val) {
  obj.update( (o) => {
    set(o, path, val);
    return o;
  });
}

function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function isNullish(value){
  return value === undefined || value === null
}

function isEmpty(obj) {
  return isNullish(obj) || Object.keys(obj).length <= 0;
}

function isDeepEmpty(obj) {
  //return false if !isEmpty(obj)
  if (Array.isArray(obj)) {
    return obj.every(o=>isDeepEmpty(o))
  }
  if (typeof obj == 'object') {
    return Object.keys(obj).every( (key) => isDeepEmpty(obj[key]) )
  }
  return isEmpty(obj)
}

function getValues(obj) {
  let results = [];
  //for (const key in obj) {
  for (const [, value] of Object.entries(obj)){
    const values = typeof value === 'object' ? getValues(value) : [value];
    results  = [...results, ...values]
    //result = result.concat(typeof obj[key] === "object" ? getValues(obj[key]) : obj[key]);
  }
  return results;
}

// TODO: refactor this so as not to rely directly on yup's API
// This should use dependency injection, with a default callback which may assume
// yup as the validation schema
function getErrorsFromSchema(initialValues, schema, errors = {}) {
  for (const key in schema) {
    switch (true) {
      case schema[key].type === 'object' && !isEmpty(schema[key].fields): {
        errors[key] = getErrorsFromSchema(
          initialValues[key],
          schema[key].fields,
          {...errors[key]},
        );
        break;
      }

      case schema[key].type === 'array': {
        const values =
          initialValues && initialValues[key] ? initialValues[key] : [];
        errors[key] = values.map((value) => {
          const innerError = getErrorsFromSchema(
            value,
            schema[key].innerType.fields,
            {...errors[key]},
          );

          return Object.keys(innerError).length > 0 ? innerError : '';
        });
        break;
      }

      default: {
        errors[key] = '';
      }
    }
  }

  return errors;
}

const deepEqual = isEqual


function assignDeep(obj, val) {
  if (Array.isArray(obj)) {
    return obj.map(o => assignDeep(o, val));
  }
  const copy = {};
  for (const key in obj) {
    copy[key] = typeof obj[key] === "object" ? assignDeep(obj[key], val) : val;
  }
  return copy;
}


function has(object, key) {
  return (
    object != undefined && Object.prototype.hasOwnProperty.call(object,key)
  )
}


// All sorts of crazy voodoo going on here,
// was this copied from an earlier version of lodash?
// removed the "res" variable as it was confusing.
//
// This returns the object, but we don't use it with a return value

function set(obj, path, value) {

  if (new Object(obj) !== obj) return obj;

  if (!Array.isArray(path)) {
    path = path.toString().match(/[^.[\]]+/g) || [];
  }

  const result = path.slice(0, -1)
    .reduce(
      (acc, key, index) =>
        new Object(acc[key]) === acc[key]
          ? acc[key]
          : (acc[key] =
               Math.trunc( Math.abs(path[index + 1])) === +path[index + 1] ? [] : {}),
      obj
    );

  result[path[path.length - 1]] = value;
  return obj;
}


// Implementation of yup.reach
// TODO rewrite to simpler version and remove dependency on forEach

// #removed
function reach(obj, path, value, context) {
  return getIn(obj, path, value, context).schema;
}

// #removed
function trim(part) {
  return part.substr(0, part.length - 1).substr(1);
}

// #removed
function getIn(schema, path, value, context) {
  let parent, lastPart, lastPartDebug;

  context = context || value;

  if (!path)
    return {
      parent,
      parentPath: path,
      schema
    };

  forEach(path, (_part, isBracket, isArray) => {
    let part = isBracket ? trim(_part) : _part;

    if (isArray || has(schema, "_subType")) {
      let index = isArray ? parseInt(part, 10) : 0;
      schema = schema.resolve({ context, parent, value })._subType;
      if (value) {
        value = value[index];
      }
    }

    if (!isArray) {
      schema = schema.resolve({ context, parent, value });
      schema = schema.fields[part];
      parent = value;
      value = value && value[part];
      lastPart = part;
      lastPartDebug = isBracket ? "[" + _part + "]" : "." + _part;
    }
  });

  return { schema, parent, parentPath: lastPart };
}

export const util = {
  assignDeep,
  cloneDeep,
  deepEqual,
  getErrorsFromSchema,
  getValues,
  isEmpty,
  isDeepEmpty,
  isNullish,
  reach,
  set,
  subscribeOnce,
  update,
};
