import { writable, get } from 'svelte/store'

const createCheckList = () => {

  class CheckList {
    constructor(list,totalSize,categories ){
      this.list = list
      this.totalSize = totalSize
      this.categories = categories
    }

    contains(item){
      return this.list.some( i => i.id == item.id)
    }

    idString(){
      return this.list.map((i) => {
        let res = ''
        if (i.hasOwnProperty('pub_class')){
          res = i.pub_class + '|'
        }
        res += i.id
        return res
      }).join('-')
    }

    selected(category){
      return this.list.filter( i => i.hasOwnProperty('catId') && i.catId == category.className).length
    }

  }

  const obj = new CheckList([],0)

  const store =  writable(obj)
  const { subscribe, set, update } = store

  return {
    store,
    subscribe,

    add: (item) => update(
      (n) => {
        if (!n.list.includes(item)){
          const list = [...n.list,item]
          const totalSize = n.totalSize + parseInt(item.file_size)
          return new CheckList(list,totalSize)
        } else {
          return n
        }
      }
    ),

    remove: (item) => update(
      (n) => {
        if (n.contains(item)){
           const list = n.list.filter((i)=>i.id!==item.id)
           const totalSize = n.totalSize - parseInt(item.file_size)
           return new CheckList(list,totalSize)
        } else {
          return n
        }
      }
    ),

    clear: () =>  set(new CheckList([], 0))

  }
}

export default createCheckList
