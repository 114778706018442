<script>
  import { fade, fly } from 'svelte/transition';
  import { tick, getContext } from 'svelte';
  import getId from './id';
  import { TABS } from './Tabs.svelte';
  const panel = {
    id: getId()
  };
  const { registerPanel, selectedPanel, labeledBy, doneOutro, completeOutro } = getContext(TABS);
  registerPanel(panel);
</script>

{#if $selectedPanel === panel && $doneOutro }
<div
  in:fade={{delay: 200, duration: 200}}
  out:fade={{delay: 0, duration: 0}}
  id={panel.id}
  on:outroend={ completeOutro }
  aria-labelledby={$labeledBy[panel.id]}
  class="svelte-tabs__tab-panel"
  role="tabpanel">
    <slot></slot>
</div>
{/if}
