<script>
  import { onMount, setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import { getRncsJSON } from '../../api.js'
  import UserRncRow from '../UserRncRow.svelte'
  import VirtualList from '../../vendor/VirtualList.svelte'
  import DownloadButtonRow from '../DownloadButtonRow.svelte'
  import createCheckList from '../../createCheckList.js'

  const checkList = createCheckList()
  setContext('checkList', checkList)

  const numNew = writable(0)
  setContext('numNew', numNew)

  const items = writable([])
  setContext('items', items)

  const byBatch = writable(false)
  setContext('byBatch', byBatch)

  const loadRNCs = async () => {
    items.set( await getRncsJSON())
  }

  onMount(
    loadRNCs
  )
</script>

<DownloadButtonRow
  resourceType='rnc'
/>

<div class="downloads_list" >
  {#if $items.length }
    {#if !$byBatch && $numNew}
      <div class="notes">green rows are updated since date specified</div>
    {/if}
    <div class="vl-container">
	  		<VirtualList items={$items} let:item >
          <UserRncRow {item} />
				</VirtualList>
		</div>
  {/if}
</div>
