import { writable } from "svelte/store"
import { getVesselCharts } from "./api.js"
import { onDestroy } from "svelte"

/** this stuff should probably be broken into
 * individual elements like all the other stores
 * */
function createStores() {
   const { subscribe, set, update } =
	   writable({
				 vesselsLoaded: false,
		     vessels: [],
		     chartsLoaded: false,
		     charts: [],
         vessel: null
			 })

	 return {
     set,
		 subscribe
	 }
}

export const stores = createStores()

export const account = writable()
export const accounts = writable([])
export const accountFormShowing = writable(false)
export const authorization = writable()
export const charts = writable([])
export const chartsLoaded = writable(false)
export const encs = writable([])
export const rncs = writable([])
export const vessel = writable()
export const vessels = writable([])
export const currentWeek = writable({id: null, week: 0, year: 0, completed: false })

export const clearOnDestroy = (theStore) => {
  onDestroy(
    () => {
      theStore.set([])
    }
  )
}

// JWT token:
export const showLoginForm = writable(false)

export const filter = ( arr, input, fields) => {
    if (input) {
      let dcFilter = input.trim().toLowerCase()

      if (dcFilter){

      return arr.filter( (item) => {
        return fields.find( (field) => {
            if (item[field]) {
              return item[field].trim().toLowerCase().includes(dcFilter)
            }
          }
        )
      })
    } else {
      return arr
    }

    } else {
      return arr
    }
}

export const updateListStoreById = (store, newObject) => {
  store.update( (list)=>{
    return replaceObjectById(list,newObject)
  })
}


export const removeListStoreItemById = (store, item) => {
  store.update( (list)=>{
    return removeObjectById(list,item)
  })
}

export const removeObjectById = (list, item) => {
  const newList = list.filter( x => x.id != item.id )
  return newList
}

export const replaceObjectById = (list, item) => {
  //console.log("list.length = " + list.length)
  const matches = list.map( (x, i) => [i, x] )
    .filter((x) => x[1].id == item.id )

  if (matches && matches.length > 0) {

    list[matches[0][0]] = item
  } else {
    list.push(item)
  }

  //console.log("list.length = " + list.length)
  return list
}

