<script>
  import { getContext } from 'svelte'
  import { updateSession, convertRodauthErrors } from '../session.js'
  import API from '../api.js'
  import { createForm } from '../vendor/svelte-forms-lib'

  import ErrorTag from './ErrorTag.svelte'
  import  * as yup from 'yup'

  export let resetKey

  const {showResetPasswordForm} = getContext('AccountRequired')

  let message

  const { form, errors, handleSubmit, handleChange } =
    createForm({
      initialValues: {password: '',
                      password_confirm: ''},

     validationSchema: yup.object().shape({
       password: yup.string()
         .required()
         .min(6, 'password should be at least 6 characters')
         .matches(/^\S.*?\S$/,
           'password cannot start or end with whitespace'),
       password_confirm: yup.string()
         .oneOf([yup.ref('password'), null], 'passwords must match')
     }),

      onSubmit: (values, forms, errors) => {
        API.post('/reset-password',
           { reset_password_key: resetKey,
             ...values })
         .then( (json) => {
            message = null
            updateSession(json)
         }).catch( err => {
           convertRodauthErrors(err,errors)
         })
       },
    })

  const returnToLogin = () => {
    console.log("returnToLogin")
    showResetPasswordForm.set(false)
  }

</script>

<div class="notice">
  Please choose a password at least 6 characters long that is different
  than any of your other passwords.
</div>

<form on:change={handleChange} on:submit={handleSubmit} >
  <label>password:
    <input type="password" name="password" bind:value={$form.password} />
    <ErrorTag {errors} field='password' />
  </label>

  <label>confirm password:
    <input type="password" name="password_confirm"
           bind:value={$form.password_confirm} />
    <ErrorTag {errors} field='password_confirm' />
  </label>

  {#if message}
    <div class="message">
      {message}
    </div>
  {/if}

    <ErrorTag {errors} field='form_errors' />

  <div class="form_buttons_div">
    <button >submit</button>
    <button on:click|preventDefault={returnToLogin}>return to login</button>
  </div>

</form>
