require ('formdata-polyfill')
import { account, authorization } from './stores.js'
import { getCookie, setCookie,
         getFromStore, dateFromJSON,
         replaceDateStrings } from './utils.js'

//const unsubFromAuth = authorization.subscribe( val => auth_key = val );

const authenticity_token = document.querySelector('meta[name="csrf-token"]').content

// This is method that returns an async method
const request = method => async (url, data) => {

  // We don't need the CSRF authenticity token on get requests
  // (though we probaby don't need to use it at all if we're
  // using JWT tokes -- but keep if for now since there's
  // no other app using our API

  let dest = method === 'GET' && data ?
            url + objectToQueryString(data) : url

  let options = { method,
                  headers: {} }

  let auth_key = getFromStore(authorization) || getCookie('auth_token')

  if (auth_key) {
    options.headers["Authorization"] = auth_key
  }

  if (!(data instanceof FormData)){
    options.headers["Content-Type"] = "application/json"
  }

  // We'll use formData if there's a file upload

  if (method !== 'GET' ){
    if (data) {
      if ( data instanceof FormData) {
        options.body = data
      } else {
        data = {...data, authenticity_token: authenticity_token}
        options.body = JSON.stringify(data)
      }
    }
  }

  // Return a promise
  return fetch(dest, options)
    .then(
      response => {
        if (response.ok) {
          setJWTTokenFromResponse(response)
          let contentType = response.headers.get("Content-Type")
          if (contentType && contentType.indexOf("application/json") == 0){
            return response.json()
          }
        } else {
          throw response
        }
      }
    ).catch( async (response) => {

       // Any response that is not "ok" is turned into
       // an error object that our forms can deal with
       let jsonBody = await response.json( (json) => json )
       console.log("in catch after json")

       throw  {
         body: jsonBody,
         status: response.status,
         statusText: response.statusText,
       }
    })
}

const setJWTTokenFromResponse = (response) => {
          let auth = response.headers.get("Authorization")
          if (auth) {
            authorization.set(auth)
            setCookie('auth_token', auth, 30)
          }
}

const objectToQueryString = (data) =>
  '?' +
  (Object.keys(data)
    .map(key=>key + '=' + data[key])
    .join('&'))

const API = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE")
}

export default API

export function addChartToVessel(chart,vesselId){
  return API.post(`/admin/vessels/${vesselId}/add_chart`, {chart_id: chart.id})
}

export function addVesselToChart(vesselId,chartId){
  return API.post(`/admin/charts/${chartId}/add_vessel`,
    {vessel_id: vesselId})
}

export function getChartsJSON(){
	return API.get("/api/charts.json")
}

export function getChartVessels(chartId){
  return API.get(`/admin/charts/${chartId}/vessel_ids`)
}

export function getEncsJSON(){
	return API.get("/api/encs.json")
    .then((json)=>replaceDateStrings(
      json, ["last_local_update"]
    ))
}

export function getAllEncsJSON(){
	return API.get("/admin/encs.json")
            .then( (json) => replaceDateStrings(json, ["file_created_at"]))
}

export async function getInitialData(){
  console.log("loading vessels")
  let vessels = await getVesselsJSON()
  console.log("loading charts")
  let charts = await getChartsJSON()
  return {chartsLoaded: true,
          charts: charts,
          vesselsLoaded: true,
          vessels: vessels}
}

export function getNoaaCoastPilotsJSON(){
	return API.get("/admin/noaa_coast_pilots.json")
}

export function getSignedURL(resourceType,
  resourceId, since, attempt, singleFile){

  let url = '/api/downloads'

  if (since) {
    if (since instanceof Date){
      url = url + '/' + since.toISOString().substring(0,10)
    } else {
      url = url + '/' + since
    }
  }
  url = url + `/${resourceType}/${resourceId}?`

  if (singleFile){
    url = url + "single_file=true&"
  }

  url = url + `attempt=${attempt}`

  console.log("getSignedURL url = " + url)

  return API.get(url)
}

export function getRncsJSON(){
	return API.get("/api/rncs.json")
            .then( (json) => replaceDateStrings(json, ["last_local_update"]))
}

export function getAllRncsJSON(){
	return API.get("/admin/rncs.json")
            .then( (json) => replaceDateStrings(json, ["file_created_at"]))
}

export function getUsersForVessel(vessel){
  return API.get(`/admin/vessels/${vessel.id}/users`)
}

export function getVesselCharts(vesselId) {
  return API.get(`/admin/vessels/${vesselId}/chart_ids`)
}

export function getVesselJSON(id){
  return API.get(`/api/vessels/${id}.json`)
}

export function getVesselsJSON(){
	return API.get("/admin/vessels.json")
}

export function getUSCGLLsJSON(){
	return API.get("/admin/uscg_lols.json")
}

export function getUSCGNtmsJSON(){
	return API.get("/admin/uscg_ntms.json")
}

export function removeChartFromVessel(chart,vesselId){
  return API.post(`/admin/vessels/${vesselId}/remove_chart`, {chart_id: chart.id})
}

export function removeVesselFromChart(vesselId, chartId){
  return API.post(`/admin/charts/${chartId}/remove_vessel`,
    {vessel_id: vesselId})
}
