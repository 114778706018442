<script>
  import { onMount, getContext } from "svelte"
  import API from "./api.js"
  import {charts, chartsLoaded, vessel, account, authorization } from './stores.js'
  import { logout, sessionData } from './session.js'
  import { getChartsJSON } from './api.js'
  import { Tab, Tabs, TabPanel, TabList } from './vendor/tabs'
  import { fade, fly } from 'svelte/transition';
  import { logVar } from './utils.js'
  import Modal from './vendor/Modal.svelte'
  import Masthead from './components/Masthead.svelte'
  import AccountRequired from "./components/AccountRequired.svelte"
  import Autocomplete from "./components/Autocomplete.svelte"
  import UserEncsTab from './components/tabs/UserEncsTab.svelte'
  import UserRncsTab from './components/tabs/UserRncsTab.svelte'
  import UserPubsTab from './components/tabs/UserPubsTab.svelte'
  import VesselSelect from './components/VesselSelect.svelte'
  import Terms from './components/Terms.svelte'
  import Privacy from './components/Privacy.svelte'

  const { open } = getContext('simple-modal')

  let loading = true

  async function callLoadVessel() {
    //console.log("callLoadVessel called")
    //logVar("$vessel", $vessel)
    //logVar("$vessel.id", $vessel.id)
    //logVar("$vessel['id']", $vessel['id'])
    loadVessel($vessel.id)
  }

  async function loadVessel(id){
    //console.log("loadVessel called")
    if ($vessel.id && charts.length > 0 && $vessel.id == id){
      //console.log("vessel loaded already")
    } else {
      if (!$vessel.id){
        //console.log("no vessel id -- user should have a vessel")
        // await getVesselJSON(id).then( json => vessel.set(json) )
      }
      await getChartsJSON().then( json => {
        charts.set(json)
        chartsLoaded.set(true)
      })
    }
  }

  async function searchVessel(searchTerm){
    console.log("searchVessel called" + searchTerm)
    if (searchTerm){
      const cleanTerm = searchTerm.trim().toUpperCase()
      return API.get("/api/vessels", {search: cleanTerm, source: 'autocomplete'})
    }
  }

  let termsShowing = false
  const showTerms = () => {
     open(Terms)
    //console.log("showing terms")
  }

  const showPrivacy = () => {
     open(Privacy)
    //console.log("showing privacy")
  }


  let vesselOptionsShowing = false
  const vesselSet = (ves) => {
    console.log("vesselSet....")
    vesselOptionsShowing = false
  }


</script>

<Masthead />

<main>
  <div class="outer_container">
    <AccountRequired>
      {#if $vessel}
        <Tabs>
          <div class="user_top_strip">
            <div class="inside">
              <span class="vessel_name">
                {#if vesselOptionsShowing}
                  <VesselSelect on:vesselSet={vesselSet} />
                {:else}
                  {#if $vessel}
                    {#if $account.vessels && $account.vessels.length > 1}
                      <button
                        on:click={() => {vesselOptionsShowing = true} }>
                      {$vessel.name}
                      </button>
                    {:else}
                      {$vessel.name}
                    {/if}
                  {/if}
                {/if}
              </span>

               {#if $account }
                 <button class="logout" on:click={logout}>log out</button>
               {/if}
             </div>

          </div>

          <TabList>
            <Tab>ENCs
              <span class='tab_new_files_notice'
              class:no_new_files={!!!$vessel.num_new_encs} >
                ({$vessel.num_new_encs} new)
              </span>
            </Tab>
            <Tab>RNCs
              <span class='tab_new_files_notice'
                class:no_new_files={!!!$vessel.num_new_rncs} >
                ({$vessel.num_new_rncs} new)
              </span>
            </Tab>
            <Tab>Pubs
              <span class='tab_new_files_notice'
                    class:no_new_files={!!!$vessel.num_new_pubs} >
                ({$vessel.num_new_pubs} new)
              </span>
            </Tab>
          </TabList>
          <div class="tab-box">
            <TabPanel >
              <UserEncsTab on:callLoadVessel={callLoadVessel} vessel={$vessel} />
            </TabPanel>
            <TabPanel >
              <UserRncsTab  on:callLoadVessel={callLoadVessel} vessel={$vessel} />
            </TabPanel>
            <TabPanel >
              <UserPubsTab  on:callLoadVessel={callLoadVessel} vessel={$vessel}/>
            </TabPanel>
          </div>
        </Tabs>

      {:else}
            <div class="notice">We don't have a vessel associated with this account
           {#if $account.admin}
              <br/><a href="/administration">Go to admin section</a>
           {:else}
              <br/>Try loging out and logging back in as a different user.
           {/if}
            </div>
      {/if}
    </AccountRequired>
  </div>

<div class="notice_overlay">
  <div id="privacy_policy" class="notice_text">
  </div>
  <div id="terms_and_conditions" class="notice_text" >
  </div>
</div>
  </main>
<footer>
  <div class="footer_frame">
    <div class="footer_frame__contact">
      <div class="company_name">
        Ocean Charting Services
      </div>
      <div class="address">
        8737 Brooks Dr. Unit 206<br/>
        Easton, Maryland 21601<br/>
        410-820-9600<br/>
        info@oceanchartingservices.com<br/>
      </div>
    </div>
    <div class="footer_frame__social" >
     <a href="https://facebook.com/" title="Facebook">
       <img src="images/fb.png" alt="facebook" />
     </a>
     <a href="https://twitter.com/" title="Twitter">
       <img src="images/tw.png" alt="twitter"/>
     <a href="https://linkedin.com/" title="LinkedIn" >
       <img src="images/in.png" alt="linkedin" />
     </a>
    </div>
    <div class="footer_frame__footer">
      copyright 2020 |
      <a href="terms" on:click|preventDefault|stopPropagation={()=>showTerms()}>terms & conditions</a> |
      <a href="privacy" on:click|preventDefault|stopPropagation={()=>showPrivacy()}>privacy policy</a>
    </div>
  </div>
</footer>
