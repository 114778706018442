<script>
  import 'element-scroll-polyfill'
	import { onMount, tick } from 'svelte';

	// props
	export let items;

	export let height = '100%';

  // prop to predetermine height of items. Passed in, never determined here...
	export let itemHeight = undefined;

	// read-only, but visible to consumers via bind:start
	export let start = 0;

	export let end = 0;

  export let viewport;

  export let displayList = true
  
  export let debugOn = false

	// local state
	let height_map = [];

  // the actual DOM element rows in the document
	let rows;

  // The svelte-virtual-list-contents DOM element that contains the rows
  // not binding in time?
	let contents;

	let viewport_height = 0;

  // array of visible items keyed by index in items, a UID (with date), and the data
	let visible;

	let mounted;

	let top = 0;

	let bottom = 0;

	let average_height;

  /**
  *  added index_id for keying rows since they were not triggering redraw when the contents of items was changing
  **/
  $: {
       visible = items.slice(start, end).map((data, i) => {
		     return { index: i + start, index_id: `${(i + start)}-${Date.now()}`,  data };
	     });
  }

  /**
   *  this needs to run only when items changes, so it needs its own reactive block
   **/
  $: items, mounted && refreshItems()

  // refreshing on itemHeight might be a problem if we are replacing item on any reactivity that causes resize
  $: if ( //mounted //&& (viewport_height || true) //  && (itemHeight || true)
        false
       )
  {
    //refresh();
  }

  async function refreshItems() {
    //console.log("refreshItems")

    if (contents === undefined){
      //console.log("refreshItems: no contents")
      //console.log(`refreshItems contents = ${JSON.stringify(contents)}`)
      return
    }

    const itemsLength = items.length

    // the rows from the contents
    await tick()
    console.log("refreshItems: after tick")
		rows = contents.getElementsByTagName('svelte-virtual-list-row');

    //top = 0
    //bottom = 0

    height_map = []

    if (itemsLength == 0) {
      start = 0
      end = 0
      top = 0
      bottom = 0
      height_map = []
      // adding a refresh here for redraw -- right?
      refresh()
      return
    }

    if (start > items.length - 1) {
      // changing start and end triggers visible
      // which changes the items shown
      start = items.length - 1
      top = 0
      bottom = 0
      height_map = []
    }
    if (end > items.length - 1){
      end = items.length - 1
      top = 0
      bottom = 0
      height_map = []
    }

    await refresh()
    await handle_scroll()
  }

	async function refresh() {

    // if items has changed, we have to check to see if start and end are still in range
     await tick(); // wait until the DOM is up to date
    //console.log("after refresh tick")

		const { scrollTop } = viewport;
    //console.log("refresh:scrollTop = " + scrollTop)
    //console.log(`refresh:top = ${top}`)

		let content_height = top - scrollTop;
    //console.log(`refresh:content_height:first = ${content_height}`)

		let i = start;

		while (content_height < viewport_height && i < items.length) {
      const currentRowIndex = i - start
			let row = rows[currentRowIndex];

			if (!row) {

        // changing end triggers a change in visible, adding
        // a new row to the DOM
				end = i + 1;
				await tick(); // render the newly visible row

				row = rows[currentRowIndex];
			}

			const row_height = height_map[i] =  itemHeight || (row && row.offsetHeight) ;

			content_height += row_height;
			i += 1;
		}

		end = i ;
		const remaining = items.length - end;
		average_height = (top + content_height) / end;
		bottom = remaining * average_height;
		height_map.length = items.length;
    //console.log(`refresh:content_height:last = ${content_height}`)
	}

	async function handle_scroll() {
    //console.log("handle_scroll")
		const { scrollTop } = viewport;
    //console.log(`scrollTop: ${scrollTop}`)
		const old_start = start;
    //console.log(`old_start: ${old_start}`)

    // set the height_map for the currently shown rows
		for (let v = 0; v < rows.length; v += 1) {
      // swap offsetHeight && itemHeight
      const startV = start + v
      //console.log(`startV=${startV}`)
      if (!height_map[startV]){
        const thisHeight = itemHeight || rows[v].offsetHeight
        //console.log(`thisHeight=${thisHeight}`)
        height_map[startV] = thisHeight
      }
		}

		let i = 0;
    // y is the imagined top of the entire list -- how tall it would be if
    // all the rows were rendered.
		let y = 0;
		while (i < items.length) {
			const row_height = height_map[i] || average_height;

      //console.log(`i=${i}, y=${y}, row_height=${row_height}`)
      // if this row would interset the top of the viewport:
			if (y + row_height > scrollTop) {

        // set the visible rows, which will rerender the list at next tick
				start = i;

        // so "top" is the height of the removed items from the top
				top = y;
        //console.log(`start=${start}, top=${top}`)
				break;
			}
			y += row_height;
			i += 1;
		}

    // run through the visible items
		while (i < items.length) {
			y += height_map[i] || average_height;
			i += 1;
      //console.log(`y=${y}, i=${i}`)
			if (y > scrollTop + viewport_height) break;
		}

		end = i;

		const remaining = items.length - end;
    //console.log(`remaining=${remaining}`)

		average_height = y / end;
    //console.log(`average_height=${average_height}`)

    //   while (i < items.length) {
    //  height_map[i] = height_map[i] || average_height;
    //  i += i
    // }

		bottom = remaining * average_height;

		// prevent jumping if we scrolled up into unknown territory

    /**
		if (start < old_start) {
      //console.log(`start < old_start, awaiting tick`)
			await tick();
			let expected_height = 0;
			let actual_height = 0;
			for (let j = start; j < old_start; j += 1) {
				if (rows[j - start]) {
          //console.log(`j=${j}`)
          //console.log(`height_map[j]=${height_map[j]}`)
          //console.log(`itemHeight=${itemHeight}`)
          //console.log(`rows[j - start].offsetHeight = ${rows[j - start].offsetHeight}`)
					expected_height += height_map[j] || average_height;
					actual_height += itemHeight || rows[j - start].offsetHeight;
          //console.log(`expected_height=${expected_height}, actual_height=${actual_height}`)
				}
			}

			const d = actual_height - expected_height;
      //console.log(`d=${d}`)
      //console.log(`scrollTop=${scrollTop}`)
      //console.log(`moving to scrollTop+d=${scrollTop + d}`)
			viewport.scrollTo && viewport.scrollTo(0, scrollTop + d);
		}
    **/

		// TODO if we overestimated the space these
		// rows would occupy we may need to add some
		// more. maybe we can just call handle_scroll again?
	}

	// trigger initial refresh
	onMount(() => {
    console.log("virtualList:mount")

		rows = contents.getElementsByTagName('svelte-virtual-list-row');
    //console.log(`onMounts: rows.length = ${rows && rows.length}`)

		mounted = true;
	});

  const rowChanged = (e) => {
    //console.log("got rowChanged event")
  }

</script>

<style>
	svelte-virtual-list-viewport {
		position: relative;
		overflow-y: auto;
		-webkit-overflow-scrolling:touch;
		display: block;
	}
	svelte-virtual-list-viewport.hidden {
		display: none;
  }
  
	svelte-virtual-list-contents, svelte-virtual-list-row {
		display: block;
	}
	svelte-virtual-list-row {
		overflow: hidden;
	}
</style>

{#if debugOn}
<div class="debug">
  displayList: {displayList}
  items: {items.length}, start: {start}, end: {end},
  visible: {visible.length}, top: {top}, bottom: {bottom},
  rows: {rows && rows.length}
</div>
{/if}

<svelte-virtual-list-viewport
	bind:this={viewport}
	bind:offsetHeight={viewport_height}
	on:scroll={handle_scroll}
	style="height:{height}"
  class:hidden={!displayList}
>
	<svelte-virtual-list-contents
		bind:this={contents}
		style="padding-top: {top}px; padding-bottom: {bottom}px;"
	>

		{#each visible as row (row.index_id)}
			<svelte-virtual-list-row idx={row.index} class:even={row.index % 2 == 0} >
				<slot item={row.data} {refreshItems} index={row.index} >Missing template</slot>
			</svelte-virtual-list-row>
		{/each}
	</svelte-virtual-list-contents>
</svelte-virtual-list-viewport>
