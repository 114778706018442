<script>
  import { getContext } from 'svelte'
  import { fly } from 'svelte/transition'
  import {  shortDate, longDateTime, pluralize } from '../utils.js'
  import { vessel } from '../stores.js'
  import DownloadLinkButton from './DownloadLinkButton.svelte'
  import DatePicker from 'svelte-calendar/src/Components/Datepicker.svelte'
  export let resourceType
  export let since

  const debugOn = false;

  const items = getContext('items')

  const earliestDate = new Date()
  earliestDate.setMonth(earliestDate.getMonth() - 12);

  const checkList = getContext('checkList')
  $: numCheckedFiles = ($checkList && $checkList.list && $checkList.list.length) || 0


  const byBatch = getContext('byBatch')

  const numNew = getContext('numNew')

  let downloadSince =  null

  const numNewResourceTypeString = 'num_new_' + resourceType + 's'
  const lastDownloadDateString = 'last_' + resourceType + '_download_date'

  let lastDownloadDate = $vessel && $vessel[lastDownloadDateString]
  let numNewString = `${$numNew||'No'} ${pluralize($numNew, resourceType.toUpperCase())}`

  $: { $vessel && numNew.set($vessel[numNewResourceTypeString])
    //console.log("numNew from vessel")
  }
  $: { $vessel && (lastDownloadDate = $vessel[lastDownloadDateString])
       since = null
       downloadSince = lastDownloadDate
    //console.log("lastDownloadDate, since from vessel")
  }
  $: { downloadSince = since || lastDownloadDate
    //console.log("downloadSince from since and lastDownloadDate")
  }
  $: { items.update( () => $items.map( (item) => {
         item.recent = downloadSince < item.last_local_update_date
         return item
       }))
       numNew.set($items.filter(item=>item.recent).length)
    //console.log("items marked recent, numNew reset")
  }
  $: { numNewString = `${$numNew||'No'} ${pluralize($numNew, resourceType.toUpperCase())}`
    // console.log("numNewString reset")
  }

  const successfulDownload = () => {
    const rightNow = new Date()
    vessel.update(
      (vessel)=>(
        {...vessel,
          [`last_${resourceType}_download_date`]: rightNow,
          [`num_new_${resourceType}s`]: 0
         }))
  }
</script>

  <div class="download_options" >
    {#if debugOn }
      numNew: {$numNew} <br/>
      numNewString: {numNewString} <br/>
      lastDownloadDate: {lastDownloadDate} <br/>
      since: {since} <br/>
      downloadSince: {downloadSince} <br/>
    {/if}

    <div class="download_type">
      {#if $byBatch}
        <a href="#" on:click|preventDefault={()=>{byBatch.set(false); return false;}}
        >
          Download Latest
        </a>
        |
        <span>
          Choose Files
        </span>
      {:else}
        <span >
          Download Latest
        </span>
        |
        <a href="#" on:click|preventDefault={()=>{byBatch.set(true); return false;}}>
          Choose Files
        </a>
      {/if}
    </div>


      {#if $byBatch}
        <div class="download_info"
          in:fly={{ x: 100, duration: 400, delay: 400 }}
          out:fly={{ x: 100, duration: 400 }}>
          <div class="flex-row">
            <div>
              <div>{numCheckedFiles} {pluralize(numCheckedFiles, 'file')}</div>
              <div>size: {parseInt($checkList.totalSize / 1024**2)}M</div>
            </div>
            {#if numCheckedFiles}
              <div class="change_buttons">
                <button on:click={()=>checkList.clear()}>clear selections</button>
              </div>
            {/if}
         </div>
       </div>
      {:else}
        <div class="download_info"
          in:fly={{ x: -100, duration: 400, delay: 400 }}
          out:fly={{ x: -100, duration: 400 }}>
        {numNewString}
        updated since
          <span class="download_date">
          {longDateTime(downloadSince)}
          </span>

        <div class="change_buttons">
            <div>
              <DatePicker
                end={new Date()}
                start={earliestDate}
                on:dateSelected={ e => since = e.detail.date }>
                <button class="custom-button">change date</button>
              </DatePicker>
            </div>

            {#if downloadSince != lastDownloadDate}
              <div>
                <button on:click={ () => since = lastDownloadDate }>
                  reset to my last download date
                </button>
              </div>
            {/if}
        </div>
        </div>
      {/if}

    <div class="download_buttons">
      {#if !$byBatch}
          <DownloadLinkButton
             resourceType={'enc'}
             since={downloadSince}
             on:success={successfulDownload}
             buttonClass={"large_download"}
             disabled={$numNew < 1}
           >
           <div slot="buttonText">
             <i class="fas fa-download" />
             Download
           <div>
         </DownloadLinkButton>
      {:else}
         <DownloadLinkButton
              resourceType='enc'
              mode='alacarte'
              ids={$checkList.idString()}
              buttonClass={"large_download"}
              disabled={numCheckedFiles == 0}
            >
              <div slot="buttonText">
                <i class="fas fa-download" />
                Download
              </div>
            </DownloadLinkButton>
      {/if}
    </div>
  </div>
