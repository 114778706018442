<script>
  import API from '../api.js'

  import { getContext } from 'svelte'

  import { convertRodauthErrors,
           nullifySession,
           updateSession } from '../session.js'

  import ErrorTag from './ErrorTag.svelte'

  import { createForm,
           handleErrors } from '../vendor/svelte-forms-lib';

  import  * as yup from 'yup'

  const { loginEmail, showResetPasswordEmailForm } =
    getContext('AccountRequired')

  let error
  let successMessage = "Make sure the email entered below is the email for the account you are trying to access, then press submit."

  const submit = (values, form, errs) => {
    API.post('/reset-password-request', values )
      .then( json => {
        if (json.success){
           successMessage = json.success
        }
      })
      .catch((fetchError) => {
         console.log("fetchError = " + JSON.stringify(fetchError))
         convertRodauthErrors(fetchError, errors)
      }).finally( () => isSubmitting.set(false) )
    return false
  }

  const { form, errors, values, handleChange,
          handleSubmit, isSubmitting }  =
    createForm( {
       initialValues: { email: $loginEmail || '' } ,
       onSubmit: submit,
       validationSchema: yup.object().shape({
         email: yup.string().required().email()
       }),
    })

  const cancel = ()=>{
    showResetPasswordEmailForm.set(false)
  }

</script>

{#if $loginEmail }
<div class="instructions notice">
{successMessage}
</div>
{/if}

<form on:submit|preventDefault={handleSubmit} >
  <div class="form_row">
  <label>email:
    <input type="email" name="email"
           bind:value={$form.email} class="long" >
    <ErrorTag {errors} field={'email'}  />
  </label>
  </div>

  <div class="form_buttons">
     <button type="submit">
       reset password
     </button>
     <button on:click={cancel}>
       cancel
     </button>
     <ErrorTag {errors} field={'form_errors'} />
  </div>

</form>
