<script>
  import retry from 'async-retry'
  import { vessel } from '../stores.js'
  import { createEventDispatcher } from 'svelte'
  import { tweened } from 'svelte/motion'
  import API from '../api.js'
  import { pluralize } from '../utils.js'

  const dispatch = createEventDispatcher()

  const transferProg = tweened(0)
  const zipProg = tweened(0)

  export let resourceType

  export let showButtonText = true

  export let buttonClass = ''

  export let since

  export let buttonText = 'download'

  export let alt = ''

  export let bytes_per_millisecond = 10245

  export let mode = 'since' // can also be "alacarte"

  export let ids

  export let disabled = false

  let sinceWas
  let button
  let submitting
  let zip_set_status = {}
  let zip_set_id

  let numFiles = 0
  let filesZipped = 0

  let upload_progress_bar_showing = false
  let zip_progress_bar_showing = false

  $: if (zip_set_status.num_files &&
    zip_set_status.num_files_zipped) {
    zip_percent = (zip_set_status.num_files / zip_set_status.files_zipped) * 100
  }

  $: if ((since instanceof Date) &&
    sinceWas != since.getTime()){
    buttonText = "download"
    if (button){
      button.disabled = disabled
    }
    sinceWas = since.getTime()
  }

  $: if (button && !disabled){
    button.disabled = submitting
  }

  const success = () => {
    dispatch("success")
  }

  const downloadTheFile = (url) => {

    zip_progress_bar_showing = false
    upload_progress_bar_showing = false

    if (url.startsWith('/')){
      url = document.location.protocol +
        '//' +  document.location.host + url
    }

    try {
      if ($vessel) {
        $vessel["last_" + resourceType + "_download"] =
          new Date().toString()
      }
    } catch {
      console.log("error on vessel assignment")
    }

    dispatch("downloaded")

    var ext = url.split('/')
      .pop()
      .split(/\#|\?/)[0]
      .split('.')
      .pop()

    if (ext.toLowerCase() == 'pdf'){
      var anch = document.createElement('a'),
        ev = document.createEvent("MouseEvents");
      anch.href = url;
      anch.target = '_blank';
      anch.download =
        decodeURIComponent(
          url.slice(url.lastIndexOf('/')+1));
      ev.initMouseEvent("click", true, false, self, 0, 0, 0, 0, 0,
        false, false, false, false, 0, null);
      anch.dispatchEvent(ev);
    } else {
      // Otherwise it's a zip file
      let iframe = document.createElement('iframe')
      iframe.style = 'display: none;'
      document.body.appendChild(iframe)
      iframe.src = url
    }
    submitting = false;
  }

  const moveZipProgressBar = () => {
    if (zip_set_status.operation == 1 &&  zip_set_status.num_files && zip_set_status.files_zipped) {
      numFiles = parseInt(zip_set_status.num_files)
      filesZipped = parseInt(zip_set_status.files_zipped)
      const percentDone = filesZipped/numFiles
      zipProg.set(percentDone)
      zip_progress_bar_showing = true
      upload_progress_bar_showing = false
      return
    }

    if (zip_set_status.operation == 2 && zip_set_status.total_size ){
      let duration =  parseInt(zip_set_status["total_size"])/bytes_per_millisecond
      transferProg.set(1, {duration: duration})
      zip_progress_bar_showing = false
      upload_progress_bar_showing = true
      return
    }
    zip_progress_bar_showing = false
    upload_progress_bar_showing = false
  }

  const getInitialUrl = () => {
    let url = `/api/downloads/${resourceType}`
    if (mode == 'since') {
      if (since == 'latest'){
        url += '/latest'
      } else {
        url += '/since/' + since.toISOString().substring(0,10)
      }
    } else {
      url += `/alacarte?ids=${encodeURIComponent(ids)}`
    }
    return url
  }

  const getZipSetUrl = (attempt) => {
    let params = `?attempt=${attempt}`

    // server decides whether set latest download
    // via alacarte param
    if (mode === 'alacarte') {
      params += '&alacarte=true'
    }
    return `/api/downloads/${resourceType}/zip_set/${zip_set_id}${params}`
  }

  const prepareDownload = async function() {
    submitting = true
    let firstTry = true

    let url = getInitialUrl()
    buttonText = 'preparing files'

    retry(
      async (bail,attempt) => {

        if (zip_set_id && attempt > 1 ) {
          url = getZipSetUrl(attempt)
        }

        let json = await API.get(url)

        // "new_url" means zip is not ready, so we retry
        // Since means we're still working on first try
        if (firstTry && json["zip_set_id"]){
          zip_set_id = json["zip_set_id"]
          url = getZipSetUrl(attempt)
          zip_set_status = json
          moveZipProgressBar()
          firstTry = false
          json = await API.get(url)
        }

        if (json.url) {
          buttonText = 'downloading'
          downloadTheFile(json.url)
          success()
          buttonText = 'downloaded'
          submitting = false
          return
        } else if (json.zip_set_id){
          zip_set_id = json.zip_set_id
          buttonText = 'preparing download'
          zip_set_status = json
          moveZipProgressBar()
          throw new Error("waiting while file is prepared")
        }

        if (json.status && json.status.indexOf('no new') == 0 ){
          buttonText = 'No new files'
          submitting = false
          success()
          return
        }
      },
      {
        retries: 40,
        factor: 1.2,
        minTimeout: 1 * 200,
        maxTimeout: 4 * 1000,
        onRetry: (err) => console.log("onRetry, err: " + JSON.stringify(err))
      }
    )
  }
</script>
<button on:click|preventDefault={prepareDownload}
  bind:this={button}
  {alt}
        title={alt}
        class="{buttonClass}"
        class:submitting={submitting}
        class:marching-ants={submitting}
        class:marching={submitting}
        class:bnw={submitting}
        disabled={disabled}
        >
<slot name="buttonText">
  <i class="fas fa-download" />
    {#if showButtonText}
     {@html buttonText}
    {/if}
</slot>

  {#if zip_progress_bar_showing}
    <progress value={$zipProg}></progress>
      zipped {filesZipped} of {numFiles} {pluralize(numFiles, 'file')}
  {/if}

  {#if upload_progress_bar_showing}
    <progress value={$transferProg}></progress>
     staging
  {/if}
</button>

<style>

   progress {
     color: blue;
     display: block;
     width: 100%;
   }

   .files_zipped_bar, .uploading_bar {
     width: 100%;
     height: 12px;
     position: relative;
     background-color: lightgray;
     margin-bottom: 2px;
   }

   .progress {
     position: absolute;
     width: 0;
     height: 12px;
     left: 0;
     top: 0;
     transition-property: width;
     transition-duration: 100s;
     background-color: blue;
   }

   .large_download {
     color: #333;
     background-color: #84cc43;
     min-width: 10rem;
     min-height: 2rem;
     font-size: 120%;
   }

   button:disabled {
    opacity: 50%;
    cursor: not-allowed;
   }

</style>
