<script>
  import { pick, logVar } from '../utils.js'
  import API from '../api.js'
  import { convertRodauthErrors,
          nullifySession, updateSession } from '../session.js'
  import { createEventDispatcher, getContext } from 'svelte';
  import { writable } from 'svelte/store'
  import { createForm, handleErrors } from '../vendor/svelte-forms-lib';
  import ErrorTag from './ErrorTag.svelte'
  import  * as yup from 'yup'

  const dispatch = createEventDispatcher();

  const {loginEmail,
         showResetPasswordEmailForm,
         resetKey,
         showResetPasswordForm } = getContext('AccountRequired')

  let message = "Please log in to continue"

  $: loginEmail.set($form.email)

  const {
     form,
     errors,
     touched,
     isValid,
     isSubmitting,
     handleChange,
     handleSubmit,
     handleReset,
     updateField,
     updateTouched,
     state
  } = createForm( {
     initialValues: {email: '',
                     password: '',
                     password_confirm: '',
                     remember: ''},

     validationSchema: yup.object().shape({
       email: yup.string().required().email(),
       password: yup.string()
         .required()
         .min(6, 'password should be at least 6 characters')
         .matches(/^\S.*\S/,
           'password cannot start or end with whitespace')
     }),

     onSubmit: (values, form, errs) => {
       if (errs && errs.length){
         console.warn("errs: " + JSON.stringify(errs));
       } else {
          API.post('/login', {...values })
          .then(json => {
            //console.log("RETURNEDJSON = " + JSON.stringify(json))
              updateSession(json)
           }).catch ( (fetchError) => {
             //console.log("convertingError")
              convertRodauthErrors(fetchError, errors)
             //console.log("convertedError")
              //nullifySession()
           }).finally( () => {
              isSubmitting.set(false)
           })
       }
     }
  })

  const handleChangeWrapper = (evt) => {
     handleChange(evt)
  }

  const returnToResetPasswordForm = () => {
    showResetPasswordForm.set(true)
  }
</script>

<div class="instructions notice">
  {message}
</div>
<div class="login_form_div">
  <form on:submit={handleSubmit} >
    <div class="form_row">
        <input name="email"
               placeholder="Email"
               on:change={handleChangeWrapper}
               bind:value={$form.email}/>
    </div>
    <ErrorTag {errors} field={'email'}  />
    <div class="form_row">
        <input name="password"
               type="password"
               placeholder="Password"
               on:change={handleChangeWrapper}
               bind:value={$form.password} />
    </div>
    <ErrorTag {errors} field={'password'}  />
    <div class="form_row">
      <ErrorTag {errors} field={'form_errors'} />
    </div>
    <div class="form_row">
      <button class='submit'>submit</button>
    </div>
    <div class="form_row">
      {#if resetKey}
        <button on:click|preventDefault={returnToResetPasswordForm} >
          return to reset password form
        </button>
       {:else}
         <button on:click|preventDefault={()=>showResetPasswordEmailForm.set(true)  }>Let me reset my password</button>
       {/if}
     </div>
  </form>
</div>
