import { authorization, account, vessel } from './stores.js'
import API from "./api.js"
import { getCookie, setCookie, getFromStore, replaceDateStringsOnOneObject } from './utils'
import { tick } from 'svelte'

// convert into formErrors useable by
// svelte-form-lib
export const convertRodauthErrors = (fetchError, errors ) => {

  if (fetchError.body){

		var fieldErrors = {}

    if (fetchError.body["field_errors"] ){

      var fe = fetchError.body["field_errors"]
			var fieldErrors = {}
			var len = fe.length

			for (var i = 0;  i < fe.length; i += 2){
				// logVar('i',i)
				fieldErrors[fe[i]] = fe[i+1]
			}
			//logVar('fieldErrors', fieldErrors)
			//logVar('errors', $errors)
    }

    if (fetchError.body["error"]) {
      fieldErrors["form_errors"] = fetchError.body["error"]
    }

		errors.update( (obj) =>{ return {...obj, ...fieldErrors }} )

  } else {
    throw fetchError
  }
}

export const getSessionFromJWT = () => {
    API.get('/api/my_account')
    .then( json => {
      updateSession(json)
    })
    // .then( setRefreshTokenTimeout )
    .catch( err => {
       //console.log("err in getSessionFromJWT: " + JSON.stringify(err))
       nullifySession()
       setCookie('auth_token', '')
     }
  )
}

export const logout = () => {
  account.set(null)
  vessel.set(null)
  API.post('/logout').then( (json) => {
    authorization.set(null)
    setCookie('auth_token', '')
  })
}

export const nullifySession = async () => {
  vessel.set(null)
  account.set(null)
  authorization.set(null)
}

const parseJwt = (token) => {
  if (!token) {
    return null
  }
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   return JSON.parse(window.atob(base64));
};


// No longer using jwt_refresh
export const refreshSession = () => {
  API.get("/jwt_refresh")
    .then( json => {
      updateSession(json)
    }).catch( err => nullifySession() )
}

// No longer using jwt_refresh
const setRefreshTokenTimeout = () => {
  formUtil.subscribeOnce(refreshTokenTimeout).then( (timeoutFunction) => {
    clearTimeout(timeoutFunction)
  })
  refreshTokenTimeout.set(setTimeout(refreshSession, 29 * 60 * 1000))
}

export const updateSession = (json) => {

  if (json?.account?.vessel?.id ) {
    console.debug(`updateSession: json.account.vessel: ${json?.account?.vessel}`)

    vessel.set(
      replaceDateStringsOnOneObject(
        json.account.vessel,
        ['last_enc_download',
         'last_pub_download',
         'last_rnc_download']
      )
    )
  }
  account.set( json.account )
}

export const weHaveAnAuthToken = () => {
  var auth = getFromStore(authorization)
  if ( auth ) {
    console.debug("auth from store")
    return auth
  }
  auth = getCookie('auth_token')
  if (auth){
    authorization.set(auth)
    console.debug("auth from cookie")
  }
  return auth
}

export const sessionData =  (token) => {
  return  parseJwt(token)
}
