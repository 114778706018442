<script>
  import { getContext, onMount, tick } from 'svelte';
  import getId from './id';
  import { TABS } from './Tabs.svelte';

  export let tabClass

  let tabEl
  const tab = {
    id: getId()
  };
  const { registerTab, registerTabElement, selectTab, selectedTab, controls } = getContext(TABS);
  let isSelected;
  $: isSelected = $selectedTab === tab;
  registerTab(tab);
  onMount(async () => {
    await tick();
    registerTabElement(tabEl);
  });
</script>

<li
  bind:this={tabEl}
  role="tab"
  id={tab.id}
  aria-controls={$controls[tab.id]}
  aria-selected={isSelected}
  tabindex="{isSelected ? -1 : 0}"
  class:active={isSelected}
  class="svelte-tabs__tab  {tabClass || ''}"
  on:click={() => selectTab(tab)}>
  <span>
	<slot></slot>
  </span>
</li>
