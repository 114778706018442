<script>
  export let adminRequired = false
  import { account, authorization } from "../stores.js"
  import API from "../api.js"
  import { onMount, setContext, getContext } from 'svelte'
  import { writable } from 'svelte/store'
  import { logout, getSessionFromJWT, sessionData,
           weHaveAnAuthToken, nullifySession } from "../session.js"
  import LoginForm from "./LoginForm.svelte"
  import ResetPasswordForm from './ResetPasswordForm.svelte'
  import ResetPasswordEmailForm from './ResetPasswordEmailForm.svelte'

  $: accountAdmin = $account && $account.admin

  let showDebug = false

  let resetKey = window.docParams["reset_password_key"]

  // for logging in
  const showResetPasswordForm = new writable(!!resetKey)
  const showResetPasswordEmailForm =  new writable(false)
  const loginEmail = new writable()

  setContext('AccountRequired',
    { showResetPasswordForm,
      showResetPasswordEmailForm,
      loginEmail,
      resetKey
    })

  onMount( async () => {
    //console.log("resetKey = " + resetKey)
			if (!$account &&
          !$showResetPasswordForm &&
          weHaveAnAuthToken() ){
        //console.debug("AccountRequired.onMount: getting session from jwt")
				getSessionFromJWT()
			}
  })

</script>

{#if showDebug}
<div>
  showResetPasswordForm={$showResetPasswordForm},
  showResetPasswordEmailForm={$showResetPasswordEmailForm},
  loginEmail={$loginEmail}
</div>
{/if}

{#if $account &&
     (!adminRequired || (adminRequired && accountAdmin)) }
  <slot></slot>
{:else}

  {#if adminRequired && $account }
    <div class='notice'>
    Sorry, your account is not authorized to view this section.
    <br/>
    <a href="/">Take me to the home page</a>
    </div>
  {:else}
    {#if showDebug && $authorization}
      <div class="notice">We have authorization, but not account</div>
    {/if}

    {#if $showResetPasswordForm}
      <ResetPasswordForm {resetKey} />
    {:else}
      {#if $showResetPasswordEmailForm}
        <ResetPasswordEmailForm />
      {:else}
        <LoginForm />
      {/if}

    {/if}
  {/if}
{/if}

{#if $account && adminRequired }
<button class="logout" on:click={logout}>log out</button>
{/if}
