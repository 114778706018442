import { derived, writable, get } from "svelte/store";
import { util } from "./util";

const NO_ERROR = "";
const IS_TOUCHED = true;

// Used to convert errors returned by Rails server
// into the format used by svelte-form-lib
export const handleErrors = (e, errors) => {
    if (e.body){
      if (e.body.errors){
        const errs = e.body.errors || {}
        errs.forEach( (err) => {
          handleOneError(err, errors)
        })
      }
    }
  }

const handleOneError = (err, errors) => {
    if (err.title) {
      util.update(errors, 'form_errors', err.title)
    }
    if (err.field_errors){
      Object.keys(err.field_errors).forEach( (k)=>{
        util.update(errors, k, err.field_errors[k])
      })
    }
  }

export const createForm = config => {
  let initialValues = config.initialValues || {};

  const extraErrorFields = config.extraErrorFields || ["form_errors"]

  /**
   *  Don't know what this is doing...
  if (!isInitialValuesValid()){
    return;
  }
  **/

  const initialValuesWithForm = Object.assign(
    Object.fromEntries(
      extraErrorFields.map(
        f => [f, '']
      )
    ), initialValues
  )


  const validateFn = config.validate;

  const validationSchema = config.validationSchema;

  const onSubmit = config.onSubmit;

  const getInitial = {
    values: () => util.cloneDeep(initialValues),
    errors: () => util.assignDeep(initialValuesWithForm, NO_ERROR),
    touched: () => util.assignDeep(initialValues, !IS_TOUCHED)
  };

  const form = writable(getInitial.values());
  const errors = writable(getInitial.errors());
  const touched = writable(getInitial.touched());
  const formTouched = writable(false);
  const isSubmitting = writable(false);
  const isValidating = writable(false);

  // Why does every field need to be touched?
  //
  const isValid = derived([errors, touched], ([$errors, $touched]) => {
    const allTouched = util
      .getValues($touched)
      .every(field => field === IS_TOUCHED);
    const noErrors = util.getValues($errors).every(field => field === NO_ERROR);
    return allTouched && noErrors;
  });

  const modified = derived(form, $form => {
    const obj = util.assignDeep($form, false);

    for (let key in $form) {
      if ($form[key] !== initialValues[key]) {
        obj[key] = true;
      }
    }

    return obj;
  });

  const isModified = derived(modified, $modified => {
    return util.getValues($modified).some(field => field === true);
  });

  function isCheckbox(el) {
    return el.getAttribute && el.getAttribute('type') === 'checkbox'
  }

  function isFileField(el) {
    return el.getAttribute && el.getAttribute('type') === 'file'
  }

  function isMultiSelect(el) {
    return el && el.tagName && el.tagName == 'SELECT' && el.multiple
  }

  function validateField(field){
    return util.subscribeOnce(form)
           .then( (values) => validateFieldValue(field, values[field]) );
  }

  function clearFormErrorsIfFirstTouch() {
    util.subscribeOnce(formTouched).then( (touched) => {
			if (!touched){
				if (extraErrorFields.includes('form_errors')){
					util.update(errors, 'form_errors', '')
				}
			}
    });
  }

  async function validateFieldValue(field, value) {
    clearFormErrorsIfFirstTouch()

    isValidating.set(true)
    updateTouched(field, true);

    let currentErrors = {[field]: ''}
    const values = get(form)

    if (validateFn) {
      mergeErrors(currentErrors, validateFn({ formValues, field }))
    }

    if (validationSchema
        && util.reach(validationSchema, field)
    ) {

      await validationSchema
        .validateAt(field, get(form))
        .then(() => {
          return true
        })
        .catch(err => {
           mergeErrors(currentErrors, {[field]: err.message})
        })
    }

    util.update( errors, field, !util.isNullish(currentErrors[field]) ? currentErrors[field] : '' )

    // redundant -- called at start of validateFieldValue...
    //updateField(field, value);
    isValidating.set(false);
  }

  function updateValidateField(field, value) {
    updateField(field, value)
    return validateFieldValue(field, value)
  }

  async function handleChange(event) {
    const el = event.target
    const field  = el.name || el.id
    const value = isCheckbox(el) ? el.checked :
      (
        isFileField(el) ?  el.files :
          ( isMultiSelect(el) ? [...el.options].filter(op => op.selected).map(op=>op.value) :
            el.value
          )
      )

    return updateValidateField(field, value);
  }

  // replace errors if current error is blank, otherwise,
  // add with a semicolon.
  function mergeErrors(currentErrors, newErrors ) {
    Object.keys(newErrors).filter( field => newErrors[field] )
      .forEach( field => {
          currentErrors[field] = [currentErrors[field], newErrors[field]]
                                 .filter(e => e).join('; ')
      })
    return currentErrors
  }

  async function handleSubmit(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    // this is not getting the last change for file fields

    let values =  get(form)
    //console.log(`handleSubmit : values = ${JSON.stringify(values)}`)
    isSubmitting.set(true);
    isValidating.set(true);

    let currentErrors = getInitial.errors()

    if (typeof validateFn === "function") {
      //console.log("about to validateFn")
      mergeErrors( currentErrors, validateFn(values, null))
    }

    if (validationSchema) {
      //console.log("about to validate schema: values = " + JSON.stringify(values))
      await validationSchema
        .validate(values, { abortEarly: false })
        .catch( yupErrs => {
          //console.log("caught in validateSchema yupErrs = " + JSON.stringify(yupErrs))

          if (yupErrs && yupErrs.inner) {
            yupErrs.inner.forEach(error => mergeErrors(currentErrors, {[error.path]: error.message}))
          }
        })
    }

    isValidating.set(false)

    //console.log(`handleSubmit after validation: form = ${JSON.stringify(form)}`)
    //console.log(`handleSubmit after validation: values = ${JSON.stringify(values)}`)
    if (util.isDeepEmpty(currentErrors)) {
      //Object.values(currentErrors).filter( (v)=>v ).length == 0){
      clearErrorsAndSubmit(values);
    } else {
      errors.set(currentErrors)
      isSubmitting.set(false)
      formTouched.set(false)
    }
  }

  function handleReset() {
    form.set(getInitial.values());
    errors.set(getInitial.errors());
    touched.set(getInitial.touched());
  }

  function clearErrorsAndSubmit(values) {

    return Promise.resolve()
      .then(() => errors.set(util.assignDeep(initialValuesWithForm, "")))
      .then(() => {
        //console.log(`clearErrorsAndSubmit: form = ${JSON.stringify(form)} `)
        onSubmit(values, form, errors)
      })
      .finally(() => {
        formTouched.set(false)
      });
  }

  function isInitialValuesValid(){
    if (Object.keys(initialValues).length < 1){
      const provided = JSON.stringify(initialValues);
      console.warn(
        `createForm requires initialValues to be a non empty object or array, provided ${provided}`
      );

      return false
    }
    return true
  }

  /**
   * Update the initial values and reset form. Used to dynamically display new form values
   */
  function updateInitialValues(newValues) {
    if (!isInitialValuesValid()) {
      return;
    }

    initialValues = newValues;

    handleReset();
  }

  /**
   * Handler to imperatively update the value of a form field
   */
  function updateField(field, value) {
    //console.log(`updating form: ${field}, ${value}`)
    util.update(form, field, value);
  }

  /**
   * Handler to imperatively update the touched value of a form field
   */
  function updateTouched(field, value) {
    formTouched.set(true);
    util.update(touched, field, value);
  }

  return {
    form,
    errors,
    touched,
    modified,
    isValid,
    isSubmitting,
    isValidating,
    isModified,
    handleChange,
    handleSubmit,
    handleReset,
    updateField,
    updateTouched,
    validateField,
    updateInitialValues,
    state: derived(
      [form, errors, touched, modified, isValid, isValidating, isSubmitting, isModified],
      ([$form, $errors, $touched, $modified, $isValid, $isValidating, $isSubmitting, $isModified]) => ({
        form: $form,
        errors: $errors,
        touched: $touched,
        modified: $modified,
        isValid: $isValid,
        isSubmitting: $isSubmitting,
        isValidating: $isValidating,
        isModified: $isModified
      })
    )
  };
};
