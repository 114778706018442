export function pick(fields, obj) {
  return fields.map(k => k in obj ?
    {[k]: nullToString(obj[k])} : {}
  ).reduce((res, o) => Object.assign(res, o), {})
}

export function transformEmptyStringToNull(value, originalValue)  {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function nullToString(obj){
  if (obj === null){
    return ''
  }
  return obj
}

export function logVar(name, obj){
  console.log(`${name}: ${JSON.stringify(obj)}`)
}

export function getCurrentWeek(){
  return 12
}

export function getCurrentYear(){
  return 2020
}

export function dateAndTime(str){
  var dt = dateFromJSON(str)
  return `${dt.toDateString()} ${dt.toTimeString().slice(0,5)}`
}

const timeRegex = /(\d{4}-\d{2}-\d{2})(\s|T)(\d{2}:\d{2}:\d{2})(\s|Z)([+-]\d{2})(:?)(\d{2})/

export function dateFromJSON(str){
  //console.log("dateFromJSON: str = " + str)
  if (str == null || str === '') {
    return null;
  }

  const utc_str = str.replace("UTC", "+00:00")
  const ds = normalizeDateString(utc_str)

  //console.log("ds = " + ds)
  return new Date(ds)
}

export function normalizeDateString(str){
  if (timeRegex.test(str)){
    return str.replace(timeRegex, '$1T$3$5:$7')
  }
  return ''
}

export function longDate(date){
  return date.toDateString()
}

const dateOptions = {
  dateStyle: 'medium',
  timeStyle: 'short'
}

export function longDateTime(date){

  return date.toLocaleString(undefined,
    dateOptions
  )
}

export function shortDate(dt){
  return dt.toLocaleDateString("en-US")
}

export function shortDateFromJSON(str){
  const dt = dateFromJSON(str)
  if (dt === null){
    return "unknown date"
  }
  return shortDate(dt)
}

export function longDateFromJSON(str){
  const dt = dateFromJSON(str)
  if (dt === null){
    return "unknown date"
  }
  return longDate(dt)
}

export function replaceDateStrings(coll, fields ){
  if (!(fields instanceof Array)){
    fields = [fields]
  }

  return coll.map( (item) => {
    return replaceDateStringsOnOneObject(item, fields)
  })
}

export function replaceDateStringsOnOneObject(item, fields){

  if (!(fields instanceof Array)){
    fields = [fields]
  }

    fields.forEach((field)=>{
      let fieldDate = field + '_date'
      item[fieldDate] = dateFromJSON(item[field])
    })
    return item
}

export function pluralize(num, word){
  if (num == 1){
    return word
  } else {
    if (['s','z'].includes(word.substr(-1,1))){
      return word + 'es'
    }
    if (['ch','sh'].includes(word.substr(-2,2))){
      return word + 'es'
    }
    return word + 's'
  }
}

export const setCookie = (cookieKey, cookieValue, expirationDays) => {
  let expiryDate = '';

  if (expirationDays) {
    const date = new Date();

    date.setTime(`${date.getTime()}${(expirationDays || 30 * 24 * 60 * 60 * 1000)}`);

    expiryDate = `; expiryDate=" ${date.toUTCString()}`;
  }

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`;
}

export const getCookie = (cookieKey) => {
  let cookieName = `${cookieKey}=`;

  let cookieArray = document.cookie.split(';');

  for (let cookie of cookieArray) {

    while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1, cookie.length);
      }

    if (cookie.indexOf(cookieName) == 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
}

export const getFromStore = (store) => {
  var storeVal
  store.subscribe(
    (val) => storeVal = val
  )();
  return storeVal
}
